<template>
  <div class="news">
    <head-nav></head-nav>
    <about-new-img></about-new-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="news-title">
        {{post.title}}
      </h3>
      <div class="news-info">
        <span class="news-time">时间：<span>{{post.createdAt  | formatDate}}</span></span>
        <span class="news-find">点击量：<span>{{hitCount}}</span></span>
      </div>

      <div
        class="news-content"
        v-html="post.content"
      >

      </div>

      <div class="new-option-link">
        <p>
          上一篇 <span
            @click="gotoPost(beforePost.id)"
            v-if="beforePost"
          >{{beforePost.title}}</span>
          <span v-else>没有更多</span>
        </p>
        <p>
          下一篇 <span
            @click="gotoPost(afterPost.id)"
            v-if="afterPost"
          >{{afterPost.title}}</span>
          <span v-else>没有更多</span>
        </p>
      </div>

    </div>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import returnImg from '@/assets/image/product/fanhui.png';

import '@/assets/icon/iconfont.css';

import HeadNav from './head-nav';
import AboutNewImg from './about-news-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    HeadNav,
    AboutNewImg,
    Footers,
    AboutNav,
  },
  apollo: {
    post: {
      query: gql`
        query($id: ID!) {
          post: Post(where: { id: $id }) {
            title
            content
            hitCount
            createdAt
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
    },
    beforeAfterPost: {
      query: gql`
        query($currentTime: DateTime) {
          afterPost: allPosts(
            where: { createdAt_gt: $currentTime }
            sortBy: createdAt_ASC
            first: 1
          ) {
            id
            title
          }
          beforePost: allPosts(
            where: { createdAt_lt: $currentTime }
            sortBy: createdAt_DESC
            first: 1
          ) {
            id
            title
          }
        }
      `,
      variables() {
        return {
          currentTime: this.post.createdAt,
        };
      },
      update(value) {
        return value;
      },
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    },
  },
  async created() {
    const result = await this.$apollo.mutate({
      // 查询语句
      mutation: gql`
        mutation($id: ID!) {
          IncrementPostHitCount(
           id: $id
          ) {
            hitCount
          }
        }
      `,
      // 参数
      variables: {
        id: this.id,
      },
    });
    this.hitCount = result.data.IncrementPostHitCount.hitCount;
  },
  data() {
    return {
      breadcrumb: [
        {
          label: '利文新闻',
          path: '/about-news',
        },
        {
          label: 'aaaaaaaaaa',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
      hitCount: 0,
      post: {
        title: '',
        content: '',
        hitCount: '',
        createdAt: '',
      },
      beforeAfterPost: {
        beforePost: [],
        afterPost: [],
      },
    };
  },
  watch: {
    post(value) {
      if (value.title) {
        this.breadcrumb = [
          {
            label: 'about.news',
            path: '/about-news',
          },
          {
            label: value.title,
          },
        ];
      }
    },
  },
  computed: {
    beforePost() {
      const { beforePost } = this.beforeAfterPost;
      if (beforePost.length > 0) {
        return beforePost[0];
      }
      return null;
    },
    afterPost() {
      const { afterPost } = this.beforeAfterPost;
      if (afterPost.length > 0) {
        return afterPost[0];
      }
      return null;
    },
  },
  methods: {
    gotoPost(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  .wrap {
    padding: 88px 0 150px 0;

    .news-title {
      text-align: center;
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
    }

    .news-info {
      margin-top: 40px;
      padding: 14px 0;
      border-bottom: 1px solid #eee;

      .news-time {
        margin-right: 64px;
      }
    }

    .news-content {
      font-size: 14px;
      line-height: 20px;

      ::v-deep p {
        margin-top: 30px;

        img {
          margin: 30px auto;
        }
      }
    }

    .new-option-link {
      padding: 16px 0;
      border-top: 1px solid #eeeeee;
      margin-top: 30px;

      p {
        margin-top: 16px;
      }
    }
  }
}
</style>
